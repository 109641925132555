import { render, staticRenderFns } from "./TabAddress.html?vue&type=template&id=798e8834&scoped=true&external"
import script from "./TabAddress.ts?vue&type=script&lang=ts&external"
export * from "./TabAddress.ts?vue&type=script&lang=ts&external"
import style0 from "./TabAddress.vue?vue&type=style&index=0&id=798e8834&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "798e8834",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/14/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('798e8834')) {
      api.createRecord('798e8834', component.options)
    } else {
      api.reload('798e8834', component.options)
    }
    module.hot.accept("./TabAddress.html?vue&type=template&id=798e8834&scoped=true&external", function () {
      api.rerender('798e8834', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/kadaster/tabs/plot/TabAddress/TabAddress.vue"
export default component.exports